import React from 'react';
import {Modal, Form, Input, Button, message} from 'antd';
import * as JStorageActions from '../Actions/JStorage';
import styled from 'styled-components';
import {PROVIDER_ID_TYPES} from '../Constants';

const initialFormValues = {
  id: '',
  name: '',
  shopee_id: '',
  yahoomall_id: '',
  rakuten_id: '',
  momomall_id: '',
  yahoo_id: '',
  momocenter_id: '',
};
function CreateInternalProductModal(props) {
  const {visible, onConfirm, onCancel = () => 0} = props;
  const [loading, setLoading] = React.useState(false);

  const [form] = Form.useForm();

  const onFinish = async (values) => {
    try {
      setLoading(true);
      let _updateValues = {...values};
      for (let key in _updateValues) {
        if (!_updateValues[key]) {
          // do not remove it, still provider default value to prevent server error
          _updateValues[key] = '';
        } else {
          let _key = key.split('_id')[0];
          if (PROVIDER_ID_TYPES[_key] === 'number') {
            _updateValues[key] = Number(_updateValues[key]);
          }
        }
      }
      let resp = await JStorageActions.createDocument('product', {
        ..._updateValues,
      });
      form.setFieldsValue(initialFormValues);
      onConfirm(values);
      message.success('商品已新增完成');
    } catch (err) {
      if (err.response?.error === 'not_unique_error') {
        message.error('無法新增商品，此商品 id 已存在');
      } else {
        message.error('無法新增商品');
      }
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = ({values, errorFields, outOfDate}) => {
    message.warn('請確認所有欄位皆已正確填寫');
  };

  const _onCancel = () => {
    form.setFieldsValue(initialFormValues);
    onCancel();
  };

  return (
    <Modal
      title="新增內部產品"
      visible={visible}
      onOk={null}
      onCancel={_onCancel}
      okText="確認"
      cancelText="取消"
      footer={null}>
      <Form
        form={form}
        name="create-internal-product"
        initialValues={{
          ...initialFormValues,
        }}
        labelCol={{span: 8}}
        wrapperCol={{span: 16}}
        initialValues={{remember: true}}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off">
        <Form.Item
          label="id"
          name="id"
          rules={[{required: true, message: '請輸入產品id'}]}>
          <Input />
        </Form.Item>

        <Form.Item
          label="name"
          name="name"
          rules={[{required: true, message: '請輸入產品名稱'}]}>
          <Input />
        </Form.Item>

        <Form.Item
          label="shopee"
          name="shopee_id"
          rules={[{pattern: /^[0-9]*$/, message: '請輸入正確的 shopee_id'}]}>
          <Input />
        </Form.Item>

        <Form.Item
          label="yahoo"
          name="yahoo_id"
          rules={[{pattern: /^[0-9]*$/, message: '請輸入正確的 yahoo_id'}]}>
          <Input />
        </Form.Item>

        <Form.Item label="yahoomall" name="yahoomall_id">
          <Input />
        </Form.Item>

        <Form.Item label="rakuten" name="rakuten_id">
          <Input />
        </Form.Item>

        {/* <Form.Item label="momomall" name="momomall_id">
          <Input />
        </Form.Item> */}

        <Form.Item label="momocenter" name="momocenter_id">
          <Input />
        </Form.Item>

        <Form.Item wrapperCol={{offset: 8, span: 16, marginTop: 40}}>
          <Button loading={loading} type="primary" htmlType="submit">
            送出
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default CreateInternalProductModal;
