import React from 'react';
import styled from 'styled-components';
import {Button} from 'antd';
import * as EditingUtil from '../../Utils/EditRecordUtil';
import EditingRecord from '../Editing/EditingRecord';
import {PROVIDERS_BY_TYPE, PROVIDER_TYPE} from '../../Constants';

function EditingGroup(props) {
  const {internalRecord, type = null} = props;

  return (
    <>
      {EditingUtil.getGroupRecords(internalRecord)
        .filter(
          (record) =>
            type === null ||
            record.provider === 'internal' ||
            PROVIDERS_BY_TYPE[type].indexOf(record.provider) !== -1,
        )
        .map((record, idx) => {
          return (
            <EditingInstanceWrapper
              key={record.provider + record.id}
              modified={EditingUtil.isRecordChanged(record)}>
              <EditingRecord
                internalRecord={internalRecord}
                record={record}
                setRecord={EditingUtil.updateRecordFn(record)}
                key={idx}
                headerActionBtn={
                  record.provider === 'internal' ? (
                    [
                      <RemoveGroupButton
                        type={type}
                        internalRecord={record}
                        style={{marginRight: 5}}
                      />,
                      <Button
                        onClick={() => EditingUtil.undoChangedRecord(record)}>
                        復原
                      </Button>,
                    ]
                  ) : (
                    <Button
                      onClick={() => EditingUtil.undoChangedRecord(record)}>
                      復原
                    </Button>
                  )
                }
              />
            </EditingInstanceWrapper>
          );
        })}
    </>
  );
}

const EditingInstanceWrapper = styled.div`
  margin-top: 5px;
  border: 1px solid #cecece;
  border-radius: 6px;
  overflow: hidden;
  ${(props) =>
    props.modified ? 'border-left: 5px solid var(--theme-color-light);' : ''};
`;

function RemoveGroupButton(props) {
  const {internalRecord, style = {}, type = null} = props;
  return (
    <Button
      style={style}
      onClick={() => {
        const groupRecords = EditingUtil.getGroupRecords(internalRecord);
        // 若 type === null 全部都要移除
        // 若 type 有值，則需移除該 type 的 record，此時若剩下的 "未移除 record" 只剩 internal record，則 internal record 也要移除
        const recordsToRemove = groupRecords.filter(
          (record) =>
            type === null ||
            PROVIDERS_BY_TYPE[type].indexOf(record.provider) !== -1,
        );
        const remainRecordsInGroup = groupRecords.filter(
          (r) => !recordsToRemove.map((_r) => _r.provider).includes(r.provider),
        );
        if (
          remainRecordsInGroup.length === 1 &&
          remainRecordsInGroup[0].provider === 'internal'
        ) {
          recordsToRemove.push(remainRecordsInGroup[0]);
        }

        for (const record of recordsToRemove) {
          EditingUtil.removeRecord(record);
        }
      }}>
      移除編輯
    </Button>
  );
}

export default EditingGroup;
