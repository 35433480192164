import React from 'react';
import styled from 'styled-components';
import {Input} from 'antd';
import getSuggestedPrice from '../../Utils/SuggestPriceCalc';
import * as EditingUtil from '../../Utils/EditRecordUtil';
import {useOutlet} from 'reconnect.js/lib';
import {
  PageTitle,
  SectionTitle,
  FieldRow,
  FieldLabel,
  FieldValue,
  SeperatorLine,
} from '../../Components/Widgets';
import {PROVIDER_DISPLAY} from '../../Constants';

// record:
// there're two kinds of `record` types
// first is what we just fetched, and the second is from outlet
// setRecord:
// when the setRecord function is not passed, it will be display only

function EditingRecord(props) {
  const {record, setRecord, headerActionBtn, internalRecord = null} = props;
  const [siteConfig, setSiteConfig] = useOutlet('site-config');
  const isInternal = record.provider === 'internal';

  const getInputProps = (field, {type} = {}) => {
    const disabledFields = ['name'];
    return {
      disabled: !setRecord || disabledFields.indexOf(field) !== -1,
      value: record[field],
      type: type || 'string',
      onChange: (evt) => {
        if (!setRecord) {
          return;
        }

        let nextValue = evt.target.value;
        if (typeof nextValue === 'string' && type === 'number') {
          if (!/^[0-9]*$/.test(nextValue)) {
            nextValue = record[field];
          }
        }
        setRecord({
          ...record,
          [field]: nextValue,
        });
      },
    };
  };

  const suggestedPrice = React.useMemo(() => {
    if (internalRecord && record) {
      // cannot get suggestedPrice without internal record
      let platformFees = siteConfig[`${record.provider}_fees`] || [];
      let editingRecord = EditingUtil.getEditingRecord(internalRecord);
      if (editingRecord) {
        return getSuggestedPrice(
          editingRecord?.cost,
          editingRecord?.shipping_cost,
          platformFees,
        );
      }
    }
    return 0;
  }, [internalRecord, record, siteConfig]);

  const isPriceValid = React.useMemo(() => {
    return record.price >= suggestedPrice;
  }, [suggestedPrice, record.price]);

  const RecordFields = [
    {
      title: '商品名稱',
      valueKey: 'name',
      isInternal: true,
      type: 'internal__provider',
    },
    {
      title: '成本',
      valueKey: 'cost',
      isInternal: true,
      type: 'internal',
      extra: {
        type: 'number',
      },
    },
    {
      title: '運費',
      valueKey: 'shipping_cost',
      isInternal: true,
      type: 'internal',
      extra: {
        type: 'number',
      },
    },
    {
      title: '商品價格',
      valueKey: 'price',
      isInternal: false,
      type: 'provider',
      extra: {
        type: 'number',
      },
    },
    {
      title: '庫存',
      valueKey: 'stock',
      isInternal: false,
      type: 'provider',
      extra: {
        type: 'number',
      },
    },
  ];

  return (
    <EditingGroupWrapper>
      <SectionTitle
        style={{
          marginLeft: 10,
          marginRight: 10,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        {isInternal
          ? '內部商品'
          : `合作通路：${PROVIDER_DISPLAY[record.provider]}`}
        <div style={{flex: 1}} />
        {headerActionBtn}
      </SectionTitle>
      <SeperatorLine />
      <div className="content-wrapper">
        <GridContainer></GridContainer>
        {RecordFields.filter(
          (f) => f.type.indexOf(isInternal ? 'internal' : 'provider') !== -1,
        ).map((f, idx) => (
          <Field key={idx}>
            <label>{f.title}</label>
            <Input
              {...getInputProps(f.valueKey, f.extra)}
              style={{marginRight: 5, width: 300}}
            />
          </Field>
        ))}
        {!isInternal && (
          <>
            <Field>
              <label>送貨方式</label>
              <Input
                {...getInputProps('delivery_id', {type: 'string'})}
                style={{marginRight: 5, width: 80}}
              />
            </Field>

            <Field>
              <label>最低售價</label>
              <Input
                disabled
                value={suggestedPrice}
                style={{marginRight: 5, width: 80}}
              />
            </Field>
          </>
        )}

        {!isInternal && !isPriceValid && (
          <div className="warning-text">
            <Field>
              <label></label>
              <div>* 商品價格必須大於等於最低售價</div>
            </Field>
          </div>
        )}
      </div>
    </EditingGroupWrapper>
  );
}

const EditingGroupWrapper = styled.div`
  padding: 10px 0px;

  & .content-wrapper {
    padding: 0px 20px;
    padding-top: 10px;

    & .warning-text {
      color: #ff3535;
      font-size: 14px;
    }
  }
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 200px 200px 200px;
`;

const Wrapper = styled.div`
  padding: 5px;
  display: flex;
  align-items: center;
`;

const Field = styled.div`
  display: flex;
  align-items: center;
  margin: 5px;

  & label {
    margin-right: 10px;
    flex-basis: 120px;
    text-align: right;
  }
`;

export default EditingRecord;
