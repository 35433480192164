import React from 'react';
import EditableCell from '../../Components/EditableCell';
import * as JStorageActions from '../../Actions/JStorage';
import * as AppActions from '../../AppActions';
import {Button, message} from 'antd';
import {PROVIDER_TYPE} from '../../Constants';

const collection = 'product';
const outletName = 'productDirtyMap';
const pageSizeOptions = [10, 30, 50];
const sortOptions = [
  {name: '價錢高到低', value: '-price'},
  {name: '價錢低到高', value: 'price'},
];

function ComparisonField(props) {
  let {isDifferent, value} = props;

  return (
    <div
      style={{
        border: isDifferent ? '1px solid red' : 'none',
        padding: '5px 10px',
      }}>
      {value}
    </div>
  );
}

async function fetchMany(
  {sort, paging, keyword, query, type, queryField = 'name'},
  onSuccess,
) {
  try {
    AppActions.setLoading(true);
    let resultQuery = {
      ...(query ? query : {}),
      ...(keyword ? {[queryField]: {$regex: `${keyword}`, $options: 'g'}} : {}),
    };

    if (type === 'center') {
      // filter data has either momocenter_id or yahoo_id
      resultQuery = {
        ...resultQuery,
        $or: [
          {
            momocenter_id: {$exists: true, $nin: ['']},
          },
          {
            yahoo_id: {$exists: true, $nin: ['']},
          },
        ],
      };
    }

    const result = await JStorageActions.fetchDocuments(
      collection,
      resultQuery,
      sort ? [sort, '_id'] : ['_id'],
      paging,
    );

    const {results: erpProducts} = await JStorageActions.fetchDocuments(
      'erp_product',
      {id: {$in: result.results.map((r) => r.id)}},
      [],
      {
        offset: 0,
        limit: paging.limit,
      },
    );

    result.results = result.results.map((r) => {
      const erpProduct = erpProducts.find((_r) => _r.id === r.id) || {};
      return {
        ...erpProduct,
        ...r,
      };
    });

    onSuccess(result);
  } catch (err) {
    message.error('無法取得商品資訊');
  } finally {
    AppActions.setLoading(false);
  }
}

async function updateMany({dirtyMap}, onSuccess) {
  const operations = [];
  for (const id in dirtyMap) {
    operations.push({
      method: 'update_one',
      payload: {
        query: {id},
        data: dirtyMap[id],
      },
    });
  }

  try {
    AppActions.setLoading(true);
    await JStorageActions.bulkWriteDocuments(collection, operations);
    await onSuccess();
  } catch (ex) {
  } finally {
    AppActions.setLoading(false);
  }
}

const makeColumns = ({
  setDirtyMap,
  latestChangeMap,
  goToDetail,
  type = null,
}) => {
  const providerCols = [];
  if (type === PROVIDER_TYPE.center || type === null) {
    providerCols.push(
      ...[
        {
          title: 'yahoo',
          key: 'yahoo_id',
          width: 200,
          render: (_, record) => {
            return (
              <EditableCell
                width={180}
                record={record}
                field="yahoo_id"
                setDirtyMap={setDirtyMap}
                latestChangeMap={latestChangeMap}
              />
            );
          },
        },
        {
          title: 'momocenter',
          key: 'momocenter_id',
          width: 200,
          render: (_, record) => {
            return (
              <EditableCell
                width={180}
                record={record}
                field="momocenter_id"
                setDirtyMap={setDirtyMap}
                latestChangeMap={latestChangeMap}
              />
            );
          },
        },
      ],
    );
  }
  if (type === PROVIDER_TYPE.mall || type === null) {
    providerCols.push(
      ...[
        {
          title: 'shopee',
          key: 'shopee_id',
          width: 200,
          render: (_, record) => {
            return (
              <EditableCell
                width={180}
                record={record}
                field="shopee_id"
                setDirtyMap={setDirtyMap}
                latestChangeMap={latestChangeMap}
              />
            );
          },
        },
        {
          title: 'yahoomall',
          key: 'yahoomall_id',
          width: 200,
          render: (_, record) => {
            return (
              <EditableCell
                width={180}
                record={record}
                field="yahoomall_id"
                setDirtyMap={setDirtyMap}
                latestChangeMap={latestChangeMap}
              />
            );
          },
        },
        {
          title: 'rakuten',
          key: 'rakuten_id',
          width: 200,
          render: (_, record) => {
            return (
              <EditableCell
                width={180}
                record={record}
                field="rakuten_id"
                setDirtyMap={setDirtyMap}
                latestChangeMap={latestChangeMap}
              />
            );
          },
        },
        // {
        //   title: 'momomall',
        //   key: 'momomall_id',
        //   width: 200,
        //   render: (_, record) => {
        //     return (
        //       <EditableCell
        //         width={180}
        //         record={record}
        //         field="momomall_id"
        //         setDirtyMap={setDirtyMap}
        //         latestChangeMap={latestChangeMap}
        //       />
        //     );
        //   },
        // },
      ],
    );
  }

  return [
    {
      title: 'SKU 編號',
      key: 'id',
      fixed: 'left',
      width: 280,
      searchable: true,
      render: (_, record) => {
        return (
          <Button
            style={{width: 100, paddingLeft: 0, textAlign: 'left'}}
            type="link"
            onClick={() => goToDetail(record)}>
            {record.id}
          </Button>
        );
      },
    },
    {
      title: 'SKU 圖',
      key: 'sku_image',
      width: 120,
      render: (_, record, index) => {
        return <img src={record.sku_image} width={100} height={100} />;
      },
    },
    {
      title: 'SKU 名稱',
      key: 'name',
      width: 500,
      searchable: true,
      render: (_, record, index) => {
        return (
          <ComparisonField
            value={record['name']}
            isDifferent={
              record._main_product &&
              record['name'] !== record._main_product['name']
            }
          />
        );
      },
    },
    {
      title: '停產註記',
      key: 'out_of_production',
      width: 150,
      render: (_, record, index) => {
        return (
          <ComparisonField
            value={record['out_of_production'] === 'ok' ? '正常' : '停產'}
            isDifferent={
              record._main_product &&
              record['out_of_production'] !==
                record._main_product['out_of_production']
            }
          />
        );
      },
    },
    {
      title: '最新採購成本',
      key: 'buy_cost',
      width: 150,
      render: (_, record, idx) => (
        <ComparisonField
          value={`${record['buy_cost'] || '---'} （${
            record['buy_cost_unit'] === 'tax_excluded' ? '未稅' : '含稅'
          }）`}
          isDifferent={
            record._main_product &&
            (record['buy_cost'] !== record._main_product['buy_cost'] ||
              record['buy_cost_unit'] !== record._main_product['buy_cost_unit'])
          }
        />
      ),
    },
    {
      title: '運費',
      key: 'shipping_cost',
      width: 120,
      render: (_, record, idx) => record['shipping_cost'],
    },
    {
      title: '市價',
      key: 'market_price',
      width: 120,
      render: (_, record, idx) => record['market_price'],
    },
    {
      title: '供應商',
      key: 'supplier',
      width: 120,
      searchable: true,
      render: (_, record, idx) => record['supplier'],
    },
    {
      title: '品牌',
      key: 'brand',
      width: 120,
      searchable: true,
      render: (_, record, idx) => record['brand'],
    },
    {
      title: 'SKU 型號',
      key: 'sku_no',
      width: 120,
      render: (_, record, idx) => record['sku_no'],
    },
    {
      title: '促銷成本',
      key: 'sale_cost',
      width: 120,
      render: (_, record, idx) => record['sale_cost'] || '---',
    },
    {
      title: '促銷日期',
      key: 'sales_expired_date',
      width: 120,
      render: (_, record, idx) => record['sales_expired_date'] || '---',
    },
    {
      title: 'inv',
      key: 'inventory',
      width: 60,
      render: (_, record) => {
        return <div>{record.inventory || 0}</div>;
      },
    },
    {
      title: 'ret',
      key: 'retention',
      width: 60,
      render: (_, record) => {
        return <div>{record.retention || 0}</div>;
      },
    },
    ...providerCols,
  ];
};

export {
  collection,
  outletName,
  pageSizeOptions,
  sortOptions,
  fetchMany,
  updateMany,
  makeColumns,
};
