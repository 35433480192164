import React from 'react';
import styled from 'styled-components';
import {Table, Pagination, Input, Select, Button, message} from 'antd';
import {
  outletName,
  pageSizeOptions,
  sortOptions,
  fetchMany, // -> FIXME: 確認是否沒有其他地方用到，沒有的話要移除或者標記
  updateMany,
  makeColumns,
} from './Define';
import {useOutlet} from 'reconnect.js';
import ProductDetail from './ProductDetail';
import {PageTitle} from '../../Components/Widgets';
import CreateInternalProductModal from '../../Components/CreateInternalProductModal';
import {PROVIDER_TYPE} from '../../Constants';
import useAntdTable from '../../Components/AntdTable/useAntdTable';
import * as JStorageActions from '../../Actions/JStorage';
import mainProductToInternalProduct from '../../Utils/MainProductToInternalProduct';

function getExtraTableProps(dimension) {
  if (!dimension.innerWidth) {
    return {};
  }

  if (dimension.rwd === 'mobile') {
    return {scroll: {x: dimension.innerWidth - 40}};
  } else {
    return {scroll: {x: dimension.innerWidth - 280}};
  }
}

const PAGE_SIZE = 10;

function AdminProductListPage(props) {
  const {
    pageContext: {type},
  } = props;
  const [actions] = useOutlet('actions');
  const [dirtyMap, setDirtyMap] = React.useState(outletName, {});
  const [lastRespTime, setLastRespTime] = React.useState(0);
  const [dimension] = useOutlet('dimension');
  const [latestChangeMap, setLatestChangeMap] = React.useState({});
  const [createModalOpen, setCreateModalOpen] = React.useState(false);
  const [selectedProduct, setSelectedProduct] = React.useState(null);
  const [rangeFilters, setRangeFilters] = React.useState([]);
  const [applyExtraFilters, setApplyExtraFilters] = React.useState(
    type === PROVIDER_TYPE.mall
      ? [{type_is_mall: true}]
      : [{type_is_center: true}],
  );
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [mergedRecords, setMergedRecords] = React.useState(null);

  const fetchData = React.useCallback(async (configs) => {
    try {
      let result = await JStorageActions.fetchDocuments(
        'product',
        configs.query,
        configs.sorting,
        configs.paging,
      );
      const {results: erpProducts} = await JStorageActions.fetchDocuments(
        'erp_product',
        {
          id: {$in: result.results.map((d) => d.id)},
        },
      );

      setDirtyMap({});
      setLastRespTime(new Date().getTime());

      let _recordsWithInvRet = result.results.map((r) => {
        let foundData = erpProducts.find((ep) => ep.id === r.sku_id);
        return {
          ...r,
          inventory: foundData ? foundData.inventory : 0,
          retention: foundData ? foundData.retention : 0,
        };
      });

      return {
        ...result,
        results: _recordsWithInvRet,
      };
    } catch (err) {
      console.error(err);
    }
  }, []);

  const {
    loading: fetchingRecords,
    records,
    tableColumns,
    onTableChange,
    antdQueryState,
    updateList,
  } = useAntdTable({
    tableColumns: makeColumns({setDirtyMap, latestChangeMap, goToDetail, type}),
    fetchRecords: fetchData,
    pageSize: PAGE_SIZE,
    outsideRangeFilters: rangeFilters,
    extraFilters: applyExtraFilters,
  });

  React.useEffect(() => {
    const _fetchMainProduct = async () => {
      setMergedRecords(null);
      const hide = message.loading('正在比對商品主檔資料...');
      try {
        let resp = await JStorageActions.fetchDocuments('main_product', {
          sku_id: {$in: records.map((r) => r.id)},
        });

        setMergedRecords(
          records.map((r) => {
            return {
              ...r,
              _main_product:
                resp.results.find((mp) => mp.sku_id === r.id) || null,
            };
          }),
        );
      } catch (err) {
        message.error('無法取得商品主檔資訊');
      } finally {
        hide();
      }
    };

    if (records && records.length > 0) {
      _fetchMainProduct();
    }
  }, [records]);

  const modifyData = () => {
    if (selectedRows.length !== 0) {
      message.warn('請先清除同步主檔勾選選取欄位');
      return;
    }
    setLatestChangeMap({...dirtyMap});
    updateMany({dirtyMap}, updateList);
  };

  function goToDetail(record) {
    setSelectedProduct(record);
  }

  // function onCreateProductBtnClick() {
  //   setCreateModalOpen(true);
  // }

  async function onProductCreate() {
    setCreateModalOpen(false);
    await updateList();
  }

  function onProductCreateCancel() {
    setCreateModalOpen(false);
  }

  async function syncDataFromMain() {
    try {
      const bulkWriteOperations = selectedRows.map((p) => {
        p = {
          ...p,
          ...(p._main_product
            ? mainProductToInternalProduct(p._main_product)
            : {}),
        };
        delete p._main_product;
        delete p._id;
        return {
          method: 'update_one',
          payload: {
            query: {id: p.id},
            data: p,
          },
        };
      });

      actions.setLoading(true);
      await JStorageActions.bulkWriteDocuments('product', bulkWriteOperations);
      await onTableRefresh();
    } catch (err) {
      console.log(err);
      message.error('無法同步主檔資訊！');
    } finally {
      actions.setLoading(false);
    }
  }

  async function onTableRefresh() {
    setSelectedRows([]);
    await updateList();
  }

  function _onTableChange(...params) {
    setSelectedRows([]);
    onTableChange(...params);
  }

  async function deleteProduct() {
    try {
      const bulkWriteOperations = selectedRows.map((p) => {
        return {
          method: 'delete_one',
          payload: {
            query: {id: p.id},
            // data: p,
          },
        };
      });

      actions.setLoading(true);
      await JStorageActions.bulkWriteDocuments('product', bulkWriteOperations);
      await onTableRefresh();
      message.success('已成功刪除內部商品（平台商品需手動下架）');
    } catch (err) {
      console.log('err', err);
      message.error('無法刪除商品');
    } finally {
      actions.setLoading(false);
    }
  }

  if (selectedProduct) {
    return (
      <Wrapper>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <Button onClick={() => setSelectedProduct(null)}>返回</Button>
        </div>

        <ProductDetail
          type={type}
          product={selectedProduct}
          style={{marginTop: 20}}
        />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <PageTitle>
        內部產品-{type === PROVIDER_TYPE.mall ? '商城' : '購物中心'}
      </PageTitle>

      {/* <Button
        size="large"
        onClick={() => onCreateProductBtnClick()}
        style={{margin: 10}}>
        新增產品
      </Button> */}

      <CreateInternalProductModal
        visible={createModalOpen}
        onConfirm={onProductCreate}
        onCancel={onProductCreateCancel}
      />

      <Table
        {...getExtraTableProps(dimension)}
        rowKey={(r) => r.id}
        loading={fetchingRecords}
        // we do data merging in records to compare for main_product data with product (internal_product)
        dataSource={mergedRecords || records}
        columns={tableColumns}
        onChange={_onTableChange}
        pagination={{
          ...antdQueryState.pagination,
          showSizeChanger: false,
        }}
        rowSelection={{
          selectedRowKeys: selectedRows.map((r) => r.id),
          onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows);
          },
          getCheckboxProps: (record) => ({
            // disabled: record.name === 'Disabled User',
            // // Column configuration not to be checked
            // name: record.name,
          }),
        }}
      />

      <div style={{display: 'flex', alignItems: 'center', padding: '20px 0'}}>
        <Button
          size="large"
          onClick={() => onTableRefresh()}
          style={{margin: 10}}>
          重新整理
        </Button>

        <Button
          size="large"
          disabled={selectedRows.length === 0}
          onClick={() => syncDataFromMain()}
          style={{margin: 10}}>
          同步主檔
        </Button>

        <Button
          type="danger"
          size="large"
          disabled={selectedRows.length === 0}
          onClick={() => deleteProduct()}
          style={{margin: 10}}>
          刪除商品
        </Button>

        <Button
          size="large"
          type="primary"
          style={{margin: 10}}
          disabled={Object.keys(dirtyMap).length === 0}
          onClick={modifyData}>
          修改平台商品 id
        </Button>

        <Button
          size="large"
          type="primary"
          style={{margin: 10}}
          onClick={async () => {
            actions.setLoading(true);
            try {
              await actions.refreshYahooProductList();
            } catch (ex) {
              console.warn(ex);
            }
            actions.setLoading(false);
          }}>
          更新Yahoo購物中心商品
        </Button>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 20px;
`;

export default AdminProductListPage;
