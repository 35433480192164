function getSuggestedPrice(_cost, _shippingCost, _platformFees) {
  let cost = Number(_cost || 0);
  let shippingCost = Number(_shippingCost || 0);
  let aggregatePaltformFee = _platformFees.reduce(
    (acc, curr, idx, arr) => acc + Number(curr.value),
    0,
  );
  // 建議售價= (成本 + 運費) / (1 - 後扣)
  if (isNaN(cost + shippingCost + aggregatePaltformFee)) {
    return 0;
  }
  return Math.round(
    ((cost + shippingCost) / (100 - aggregatePaltformFee)) * 100,
  );
}

export default getSuggestedPrice;
